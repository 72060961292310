import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import axios from 'axios';
import VueTheMask from 'vue-the-mask';
import Toast from 'vue-toastification';
import store from './store';
import moment from 'moment';
import DatetimePicker from 'vuetify-datetime-picker';
import 'moment/locale/pt-br'
import 'vue-toastification/dist/index.css';
import './registerServiceWorker';
import validateAuth from '@/utils/validateAuth';

moment.locale('pt-br');

Vue.use(Toast, {
  transition: 'Vue-Toastification__bounce',
  maxToasts: 20,
  newestOnTop: true,
  pauseOnFocusLoss: false,
});

Vue.use(VueTheMask);
Vue.use(DatetimePicker);

Vue.mixin({
  methods: {
    formatPrice(value) {
      let val = (+value || 0).toFixed(2).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    formatDate(dh, format = 'DD/MM/YYYY HH:mm') {
      return dh ? this.moment(dh).format(format) : '';
    },
  }
});

Vue.config.productionTip = false;

const baseURL = (document.URL.indexOf('localhost') !== -1)
  ? 'http://revendafacil-api.localhost:8000/api'
  : 'https://revenda-api.lojafacil.app/api';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;

Vue.prototype.$http = axios.create({ baseURL });
Vue.prototype.$urlAPI = baseURL;
Vue.prototype.moment = moment;

const makeVueInstance = async () => {
  await validateAuth();

  new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
}

makeVueInstance();

import axios from 'axios';
import store from '@/store';

export default async function validate() {
  const baseURL = (document.URL.indexOf('localhost') !== -1)
    ? 'http://revendafacil-api.localhost:8000/api'
    : 'https://revenda-api.lojafacil.app/api';

  const http = axios.create({ baseURL });

  const token = sessionStorage.getItem('token');
  http.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
  http.defaults.headers.common['Authorization'] = `Bearer ${token}`;

  try {
    await http.get('validate-token').then(resp => {
      const { user } = resp.data;

      // if (!user) {
      //   location.reload();
      // }

      store.commit('setUser', user);
    }).catch(() => {
      console.log('Não foi possivel concluir a operação.');
      // alert(baseURL);
      window.location.reload(true);
    });
  } catch (error) {
    console.log(error);
    document.location.href="/";
    // window.location.reload(true);
  }
}
